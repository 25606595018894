.home {
  height: 91vh;
  background-image: url("../../assets/texture.png");
  padding-top: 0;
  margin-top: 0;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .jumbo-text {
    text-align: center;
    color: var(--off-white-color);
    font-size: 2rem;
    line-height: 8vw;
    padding: 1.5rem 1rem;

    .jumbo-text-bold {
      font-size: 5vw;
      font-weight: 900;

      span {
        font-weight: 400;
      }
    }
  }
  .jumbo-image {
    justify-content: center;

    .image {
      max-height: 67vh;
      max-width: 100%;
      margin-top: -3rem;
    }
  }

  @media only screen and (min-width: 960px) {
    .main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
    }

    .jumbo-text {
      display: flex;
      flex: 3;
      flex-direction: column;
      justify-content: center;
      margin-left: 4%;
      text-align: left;
      color: var(--off-white-color);
      font-size: 2rem;
      line-height: 6.5vw;
      padding-bottom: 4rem;

      .jumbo-text-bold {
        font-size: 3vw;
        font-weight: 900;
        span {
          font-weight: 400;
        }
      }
    }
    .jumbo-image {
      flex: 2.1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      margin-right: 8%;

      .image {
        max-height: 67vh;
        max-width: 100%;
        margin-top: -3rem;
      }
    }
  }
}
