.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.5rem;
  background-color: var(--main-bg-color);

  .logo-container {
    min-height: 8vh;
    display: flex;
    align-items: center;
    text-decoration: none;

    .logo {
      margin: 0;
      font-weight: 800;
      color: var(--off-white-color);
    }
  }

  @media only screen and (max-width: 959px) {
    .menuicon {
      display: block;
      cursor: pointer;
      color: white;

      transform: rotate(0deg);
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

      &__bar,
      &__circle {
        fill: none;
        stroke: currentColor;
        stroke-width: 3;
        stroke-linecap: round;
      }
      &__bar {
        transform: rotate(0deg);
        transform-origin: 50% 50%;
        transition: transform 0.25s ease-in-out;
      }
      &__circle {
        transition: stroke-dashoffset 0.3s linear 0.1s;
        stroke-dashoffset: circumference(23); // 23 is the <circle>'s radius
        stroke-dasharray: circumference(23);
      }
    }

    // ---------------------------
    // Active State
    // ---------------------------

    .nav:target,
    .nav--open {
      //animate the menu icon
      .menuicon {
        color: white;
        transform: rotate(180deg);

        &__circle {
          stroke-dashoffset: 0;
        }

        &__bar:nth-child(1),
        &__bar:nth-child(4) {
          opacity: 0;
        }
        &__bar:nth-child(2) {
          transform: rotate(45deg);
        }
        &__bar:nth-child(3) {
          transform: rotate(-45deg);
        }
      }

      //show the nav items
      + .links {
        visibility: visible;
        text-align: center;
      }
    }

    .links {
      position: absolute;
      top: 8vh;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      background-color: rgba($color: #170312, $alpha: 0.98);
      width: 100%;
      height: 92vh;
      visibility: hidden;
      justify-content: space-between;

      .content-links {
        display: flex;
        flex-direction: column;
      }

      .nav-link {
        text-decoration: none;
        margin: 0 1rem;
        padding: 1.5rem 2rem;
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--off-white-color);
      }
      .menu-button {
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--off-white-color);
        background-color: var(--primary-color);
        padding: 0.95rem 1.15rem;
        border: 0.1rem solid var(--primary-color);
        cursor: pointer;
        z-index: 2000;
      }
    }
  }
}

@media only screen and (min-width: 960px) {
  .nav__toggle {
    display: none;
  }

  .links {
    display: flex;
    flex-direction: row;

    .content-links {
      display: flex;
      flex: 1;
    }

    .nav-link {
      text-decoration: none;
      padding: 1.5rem 2rem;
      font-size: 1.2rem;
      font-weight: 700;
      color: var(--off-white-color);

      &:hover {
        background-color: rgba(#718f89, 0.25);
        padding: 1.5rem 2rem 1.2rem 2rem;
        border-bottom: 0.3rem solid var(--primary-color);
      }
    }

    .menu-button {
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: 700;
      color: var(--off-white-color);
      background-color: var(--primary-color);
      padding: 0.95rem 1.15rem;
      border: 0.1rem solid var(--primary-color);
      margin: 0.55rem 0.75rem;
      border-radius: 3px;
      margin-right: 0;
      margin-left: 1.25rem;
      cursor: pointer;
      z-index: 2000;
    }
  }
}

.navigation__checkbox {
  display: none;
}

.navigation__background {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: -6rem;
  right: -6rem;
  background-image: radial-gradient(var(--primary-color), #28b485);
  z-index: 999;
  transition: all 500ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.navigation__nav {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1005;
  opacity: 0;
  visibility: hidden;
  transition: opacity 400ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
  padding: 0;
}

.navigation__item {
  margin: 1rem;
  width: calc(100% - 3rem);
}

.navigation__link:link,
.navigation__link:visited {
  display: inline-block;
  font-size: 3rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 225%;
  transition: all 0.5s;
}
.navigation__link:link span,
.navigation__link:visited span {
  margin-right: 1rem;
  display: inline-block;
}

.navigation__link:hover,
.navigation__link:active {
  color: var(--color-primary);
  background-position: 100%;
  transform: translateX(1rem);
}

.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(60);
}

.navigation__checkbox:checked ~ .navigation__nav {
  opacity: 1;
  visibility: visible;
}

.menu-active {
  display: none;
}

.navigation__icon,
.navigation__icon::before,
.navigation__icon::after {
  width: 3rem;
  height: 4px;
  background-color: #333;
  display: inline-block;
  transition: all 0.2s;
}

.navigation__checkbox:checked + .menu-button {
  border: 0.1rem solid white;
}

.navigation__checkbox:checked + .menu-button .menu-active {
  display: inline;
}

.navigation__checkbox:checked + .menu-button .menu-inactive {
  display: none;
}
