.form__group:not(:last-child) {
  margin-bottom: 2rem;
}

.select {
  display: flex;
  font-size: 1.2rem;
  flex-direction: row;
  width: 96%;
}

@media only screen and (max-width: 960px) {
  .select {
    flex-direction: column;
  }
}

.select-label {
  display: flex;
  padding-bottom: 1rem;
  flex: 1;
}

.contact-title {
  font-size: 1.9rem;
}

.form__message {
  min-height: 9rem;
}

.form__input {
  font-size: 1.2rem;
  font-family: inherit;
  color: inherit;
  padding: 1rem 1.25rem;
  border-radius: 2px;
  background-color: rbga(#fff, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}

.form__select {
  font-size: 1.2rem;
  font-family: inherit;
  color: inherit;
  border-radius: 2px;
  background-color: rbga(#fff, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  display: flex;
  flex: 5;
  transition: all 0.3s;
  padding: 5px;
  line-height: 1;
  border: 0.5px solid var(--secondary-color);
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media only screen and (max-width: 960px) {
  .form__input {
    width: 100%;
  }
}
.form__input:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid var(--primary-color);
}
.form__input:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input::-webkit-input-placeholder {
  color: #999;
}

.form__label {
  font-size: 1rem;
  font-weight: 700;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  display: block;
  transition: all 0.3s;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.form__radio-group {
  width: 49%;
  display: inline-block;
}
@media only screen and (max-width: 960px) {
  .form__radio-group {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.form__radio-input {
  display: none;
}

.form__radio-label {
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  padding-left: 3.5rem;
  color: var(--secondary-color);
}

.form__radio-button {
  height: 1.3rem;
  width: 1.3rem;
  border: 5px solid #55c57a;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0.2rem;
  top: -0.15rem;
}
.form__radio-button::after {
  content: "";
  display: block;
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #55c57a;
  opacity: 0;
  transition: opacity 0.2s;
}

.form__radio-input:checked ~ .form__radio-label .form__radio-button::after {
  opacity: 1;
}

// Book section
.section-book {
  padding: 6rem 0;
  background-color: var(--secondary-color);

  .heading-secondary {
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 960px) {
  .section-book {
    padding: 3rem 0;
  }
}

.book {
  background-color: rgba(240, 239, 243, 0.95);
  background-size: 100%;
  border-radius: 3px;
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
  margin: 3vw;
}
@media only screen and (max-width: 960px) {
  .book {
    background-color: rgba(240, 239, 243, 0.95);
  }
}
.book__form {
  width: 75%;
  padding: 3rem;
}

.row {
  max-width: 80rem;
  margin: 0 auto;
  flex-direction: row;
}
.row:not(:last-child) {
  margin-bottom: 2rem;
}
@media only screen and (max-width: 960px) {
  .row:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.row::after {
  content: "";
  display: table;
  clear: both;
}
.row [class^="col-"] {
  float: left;
}
.row [class^="col-"]:not(:last-child) {
  margin-right: 6rem;
}
@media only screen and (max-width: 960px) {
  .row [class^="col-"]:not(:last-child) {
    margin-right: 0;
    margin-bottom: 6rem;
  }
}

@media only screen and (min-width: 960px) {
  .row .col-1-of-2 {
    width: calc((100% - 6rem) / 2);
  }
  .row .col-1-of-3 {
    width: calc((100% - 2 * 6rem) / 3);
  }
  .row .col-2-of-3 {
    width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem);
  }
  .row .col-1-of-4 {
    width: calc((100% - 3 * 6rem) / 4);
  }
  .row .col-2-of-4 {
    width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem);
  }
  .row .col-3-of-4 {
    width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem);
  }
}
