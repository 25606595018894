html {
  scroll-behavior: smooth;
}

input {
  -webkit-appearance: none;
  box-shadow: none !important;
}
:-webkit-autofill {
  color: #fff !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: expo-sans-pro, sans-serif;
  --main-bg-color: #170312;
  --primary-color: #718f89;
  --secondary-color: #222e50;
  --tertiary-color: #ffe500;
  --off-white-color: #fffeff;
  background-color: var(--main-bg-color);
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-medium {
  margin-bottom: 3rem !important;
}

.u-margin-bottom-small {
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}

.heading {
  font-size: 1.4rem;
  color: var(--secondary-color);
}

.heading-secondary {
  font-size: 3.25rem;
  line-height: 1.25;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  font-weight: 700;
  background-image: linear-gradient(
    to right,
    var(--primary-color) 0%,
    var(--tertiary-color) 200%
  );
  display: inline-block;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

@media only screen and (max-width: 960px) {
  .heading-secondary {
    font-size: 2rem;
  }
}

.heading-tertiary {
  font-family: expo-sans-pro;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 0;
  line-height: 1;
}

.paragraph {
  font-family: expo-sans-pro, sans-serif;
  line-height: normal;
  line-height: 1.8rem;
  font-size: 1rem;
}
.paragraph:not(:last-child) {
  margin-bottom: 2.2rem;
}

.caption {
  font-family: expo-sans-pro, sans-serif;
  line-height: normal;
  font-size: 0.9rem;
  padding: 0;
  margin: 1.75rem 0 0 0;
}
.caption:last-child {
  margin-bottom: 3rem;
}

.img-comp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img-comp__photo {
  width: 65%;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 0.25rem;

  transition: all 0.1s;
  outline-offset: 0.75rem;
}
.img-comp__photo--p2 {
  margin-top: 2rem;
}
.img-comp__photo:hover {
  outline: 1.2rem solid var(--primary-color);
  transform: scale(1.1);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.small-space {
  margin: 0;
  padding-top: 0.05rem;
  font-size: 1rem;
}

.btn,
.btn:link,
.btn:visited {
  display: inline-block;
  position: relative;
  text-decoration: none;
  padding: 0.8rem 2.5rem;
  font-size: 1.3rem;
  border-radius: 5rem;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn:active,
.btn:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.btn--green {
  background-color: #55c57a;
  color: #fff;
}
.btn--green::after {
  background-color: #55c57a;
}

.react-images__footer__caption {
  font-family: expo-sans-pro, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  font-size: 1.5rem;
}
