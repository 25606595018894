.history {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--off-white-color);
  background-color: var(--secondary-color);
}

.features-row {
  font-size: 1.8rem;
  margin: 3rem 9vw;

  p {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  li {
    font-size: 1.1rem;
    font-weight: bold;
    padding: 0.05rem;
    margin: 2rem 0 0 0;
  }
}

.history__content {
  max-width: 75rem;
}

.heading-center {
  padding: 0.75rem 0;
  text-align: center;
}
