.about {
  background-color: var(--secondary-color);
  padding: 2rem 1rem 0 1rem;
  font-size: 1.2rem;
  line-height: 2.25rem;
  color: var(--off-white-color);

  .btn-text:link,
  .btn-text:visited {
    color: var(--off-white-color);
    font-size: 1rem;
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid var(--main-bg-color);
    padding: 0.5rem;
    margin-left: 1.5rem;
    text-align: center;
    transition: all 0.2s;
  }

  .btn-text::after {
    content: "";
    display: inline-block;
    border-radius: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: scaleY(2);
  }

  .btn-text:hover {
    background-color: var(--main-bg-color);
    color: #fff;
    padding: 0.5rem 2rem;
    margin-left: 0;
    border-radius: 2rem;
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15);
    transform: translateY(-0.3rem);
  }

  .btn-text:active {
    transform: translateY(-0.1rem);
    box-shadow: 0 0.1rem 0.75rem rgba(0, 0, 0, 0.15);
  }
}

@media only screen and (min-width: 960px) {
  .about {
    padding: 1rem 5rem 3rem 5rem;
  }
}

.row {
  max-width: 80rem;
  margin: 0.1rem auto;
  flex-direction: row;
}
.row:not(:last-child) {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 960px) {
  .row .col-1-of-2 {
    width: calc((100% - 6rem) / 2);
  }
  .row .col-1-of-3 {
    width: calc((100% - 2 * 6rem) / 3);
  }
  .row .col-2-of-3 {
    width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem);
  }
  .row .col-1-of-4 {
    width: calc((100% - 3 * 6rem) / 4);
  }
  .row .col-2-of-4 {
    width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem);
  }
  .row .col-3-of-4 {
    width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem);
  }
}
